import { AltLayout } from '../layouts/AltLayout';
import Blog from '@/common/blog';
import { graphql, useStaticQuery } from 'gatsby';
import { SEO } from '@/common/seo';

const Blogs: Page = () => {
    const data = useStaticQuery(
        graphql`
            query Blogs {
                allFile(filter: { sourceInstanceName: { eq: "blogs" } }) {
                    nodes {
                        childrenMdx {
                            frontmatter {
                                description
                                featuredImage
                                title
                            }
                        }
                    }
                }
            }
        `
    );
    const blogs = data.allFile.nodes;

    return (
        <AltLayout
            heroImg="BlogsHero.jpg"
            heroButtonText="Get In Touch With Us"
            label="Blogs"
            title="Learn About Oral Health"
            description="We have compiled a lot of information about oral health in order for our patients to benefit from it"
        >
            <div className="mt-16 lg:mt-28">
                <div className="DH2MH3 text-primary ml-1">Latest Blogs</div>
                <div className="flex lg:grid lg:grid-cols-3 gap-3 lg:gap-8 overflow-x-auto no-scrollbar pb-14 -mx-2 px-2">
                    {blogs.map((blog: any, i: number) => (
                        <Blog blog={blog} key={i} />
                    ))}
                </div>
            </div>
        </AltLayout>
    );
};

export default Blogs;

export const Head = () => (
    <SEO
        title="Learn about Oral Health - Dental Blogs - Zapata Dental"
        description="Zapata Dental has compiled a lot of information about oral health in order for our patients to benefit from it."
        pathname="/blogs/"
    />
);
